import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '../../../admin/responsibility/searchModule';
import appStrings from '@/app/utility/string.utility';
import party from '@/app/components/generalLedger/party';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'addEditInterestRate',
  components: {
    ModulesList,
    party,
    DatePicker,
  },
  props: ['interestRateRowData'],
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      vsetCode: null,
      parent_value_set_id: null,
      editMode: false,
      showModal: false,
      showValueSetModal: false,
      tab: null,
      showPartyModal: false,
      irmId: 0, 
      selectedModule: {
        name: null,
        id: null
      },
      project: {
        value: null,
        text: null
      },
      customer: {
        name: null,
        id: null
      },
      calType: {
        value: null,
        text: null
      },
      unit: {
        value: null,
        text: null
      },
      subUnit: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      interestRate: null,
      periodFrom: null,
      periodTo: null,
    };
  },
  validations: {
    selectedModule: {
      name: {
        required
      }
    },
    periodFrom: {
        required
    },
    periodTo: {
        required
    }
  },
  mounted() {
    if (this.interestRateRowData) {
      this.getInterestRateById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditInterestRateData();
        }
      }
    });
  },
  methods: {
    getInterestRateById() {
      const payload = {
        irm_id: this.interestRateRowData.irm_id
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/getInterestRateById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.calType.text = result.irm_calc_type;
            this.calType.value = result.irm_calc_type;
            this.customer.id = result.irm_customer_id;
            this.customer.name = result.irm_customer_name;
            this.periodFrom = result.irm_effective_from_date;
            this.periodTo = result.irm_effective_to_date;
            this.irmId = result.irm_id;
            this.interestRate = result.irm_interest_rate;
            this.selectedModule.id = result.irm_module_id;
            this.selectedModule.name = result.irm_module_name;
            this.project.text = result.irm_prj_name;
            this.project.value = result.irm_project_id;
            this.subUnit.value = result.irm_sub_unit_id;
            this.subUnit.text = result.irm_sub_unit_name;
            this.unit.value = result.irm_unit_id;
            this.unit.text = result.irm_unit_name;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditInterestRateData() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      const payload = {
        irm_id: this.irmId, 
        irm_calc_type: this.calType.value, 
        irm_interest_rate: this.interestRate, 
        irm_module_id: this.selectedModule.id, 
        irm_prj_id: this.project.value, 
        irm_customer_id: this.customer.id, 
        irm_unit_id: this.unit.value, 
        irm_sub_unit_id: this.subUnit.value, 
        irm_effective_from_date: commonHelper.formattedDate(this.periodFrom), 
        irm_effective_to_date: commonHelper.formattedDate(this.periodTo)
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/addEditInterestRateData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.irmId = response.data.data.irm_id;
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.parent_value_set_id = this.unit.value;
      } 
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
       if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT
      ) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ACTIVITY_TYPE_VSET) {
        this.activityType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INTEREST_CALC_TYPE) {
        this.calType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST
      ) {
        this.unit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.subUnit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.selectedModule.id) {
        this.selectedModule = {
        name: null,
        id: null
        };
        this.project = {
            text: null,
            value: null
        };
        this.unit.text = null;
        this.unit.value = null;
    } else if (vsetCode === this.project.value) {
        this.project = {
        text: null,
        value: null
        };
    } else if (vsetCode === this.customer.id) {
        this.customer = { id: null, name: null };
    } else if (vsetCode === this.calType.value) {
        this.calType.text = null;
        this.calType.value = null;
    } else if (vsetCode === this.unit.value) {
        this.unit.text = null;
        this.unit.value = null;
    } else if (vsetCode === this.subUnit.value) {
        this.subUnit.text = null;
        this.subUnit.value = null;
    } 
    },
    getSelectedPartyCustomer(item) {
        this.getCustomerIdByPartyId(item.party_id);
        this.customer.name = item.party_name;
        this.customer.id = item.party_id;
        this.showPartyModal = false;
      },
    showHidePartyModal(flag) {
        this.showPartyModal = flag;
    },
    getCustomerIdByPartyId(partyId) {
      this.loader = true;
      this.$store
        .dispatch('receivables/getCustomerIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.customer.id = response.data.data;
          }
        });
    },
    openModuleBasedProject(moduleName) {
      if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      } else {
        alert('Please Select Module First');
      }
    },
    openModuleBasedUnit(moduleName){
        if (moduleName === 'FMS') {
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_UNIT);
          } else if (moduleName === 'Lease Management') {
            this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_UNIT_LIST);
          } else {
            alert('Please Select Module First');
          }
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.project.text = null;
      this.project.value = null;
      this.unit.text = null;
      this.unit.value = null;
      this.showModal = false;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
