import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '../../admin/responsibility/searchModule';
import appStrings from '@/app/utility/string.utility';
import party from '@/app/components/generalLedger/party';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
import addEditInterestRate from './addEditInterestRate';
export default {
  name: 'interestRate',
  watch: {
    currentPage: function() {
      this.getInterestRateData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInterestRateData();
    }
  },
  components: {
    ModulesList,
    party,
    DatePicker,
    addEditInterestRate
  },
  data() {
    return {
      payload: null,
      unsubscribe: null,
      showModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      tab: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      vsetCode: null,
      showPartyModal: false,
      selectedModule: {
        name: null,
        id: null
      },
      project: {
        value: null,
        text: null
      },
      customer: {
        name: null,
        id: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      periodFrom: null,
      periodTo: null,
      interestRateData: [],
      interestRateFields: [
        {
          key: 'irm_module_name',
          label: 'Module'
        },
        {
          key: 'irm_prj_name',
          label: 'Project'
        },
        {
          key: 'irm_customer_name',
          label: 'Customer Name'
        },
        {
          key: 'irm_interest_rate',
          label: 'Interest Rate'
        },
        {
          key: 'irm_effective_from_date',
          label: 'Effective From Date'
        },
        {
          key: 'irm_effective_to_date',
          label: 'Effective To Date'
        },
        {
          key: 'irm_calc_type',
          label: 'Calculation Type'
        },
        {
          key: 'irm_unit_name',
          label: 'Unit'
        },
        {
          key: 'irm_sub_unit_name',
          label: 'Sub Unit'
        }
      ],
      showInterestRateModal: false,
      interestRateRowData: null
    };
  },
  validations: {
    selectedModule: {
      name: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showInterestRateModal = true;
          this.interestRateRowData = null;
        }
        // if (actionName === 'download' && !this.showActivityModal) {
        //   this.loader = true;
        //   const downloadPayload = { ...this.payload };
        //   downloadPayload._limit = this.totalRows;
        //   this.downloadExcel.downloadData(
        //     downloadPayload,
        //     'applyReceipt/getInterestRateData',
        //     'activity-list',
        //     () => {
        //       this.loader = false;
        //     }
        //   );
        // }
      }
    });
  },
  methods: {
    getInterestRateData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        irm_module_id: this.selectedModule.id,
        irm_project_id: this.project.value,
        irm_customer_id: this.customer.id,
        irm_effective_from_date: commonHelper.formattedDate(this.periodFrom),
        irm_effective_to_date: commonHelper.formattedDate(this.periodTo),
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/getInterestRateData', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.interestRateData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    selectedvalueSet(item) {
       if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT
      ) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ACTIVITY_TYPE_VSET) {
        this.activityType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    getSelectedPartyCustomer(item) {
        this.getCustomerIdByPartyId(item.party_id);
        this.customer.name = item.party_name;
        this.customer.id = item.party_id;
        this.showPartyModal = false;
    },
    getCustomerIdByPartyId(partyId) {
        this.loader = true;
        this.$store
          .dispatch('receivables/getCustomerIdByPartyId', partyId)
          .then(response => {
            if (response.status === 200) {
              this.loader = false;
              this.customer.id = response.data.data;
            }
          });
    },
    showHidePartyModal(flag) {
        this.showPartyModal = flag;
    },
    openModuleBasedProject(moduleName) {
      if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      } else {
        alert('Please Select Module First');
      }
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.project.text = null;
      this.project.value = null;
      this.showModal = false;
    },
    showhideInterestRateModal(flag) {
        this.showInterestRateModal = flag;
        if(!flag){
          this.getInterestRateData();
        }
    },
    rowSelected(item) {
        this.interestRateRowData = item;
        this.showInterestRateModal = true;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.selectedModule.id) {
        this.selectedModule = {
        name: null,
        id: null
        };
    } else if (vsetCode === this.project.value) {
        this.project = {
        text: null,
        value: null
        };
    } else if (vsetCode === this.customer.id) {
        this.customer = { id: null, name: null };
      } 
    },
    clearFilters() {
      this.project.text = null;
      this.project.value = null;
      this.selectedModule = {
        name: null,
        id: null
      };
      this.periodFrom = null;
      this.periodTo = null;
      this.customer = { id: null, name: null };
      this.interestRateData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
